import { Icon } from '@iconify/react';
import { Box } from '@mui/material';
import CustomizedInputsStyled from 'app/components/BootstrapInput';
import ButtonControlled from 'app/components/Button';
import React, { useRef } from 'react';
import { AssetManagementProtype } from 'types/Assets';
import GoBackIcon from 'assets/icons/go-back.svg';
import TickGreenIcon from 'assets/icons/tick-green.svg';
import TrashIcon from 'assets/icons/trash.svg';
import { EllipsisText } from 'app/components/EllipsisText';
import toast from 'react-hot-toast';

export const RenderHeaderInput = ({
  item,
  handleChange,
  handleRemove,
}: {
  item: AssetManagementProtype;
  handleRemove: () => void;
  handleChange: (id: string, v: string, f: string) => void;
}) => {
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string>('');
  const titleInput: any = useRef(null);

  const handleChangeValueNameAsset = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setValue(event.target.value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {!isEdit ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              borderRadius: '34px',
              background: '#FF66A3',
              maxWidth: 'max-content',
              height: 'max-content',
              padding: '4px 8px',
              lineHeight: '21px',
              color: '#FFF',
              fontSize: '14px',
              fontWeight: 600,
              marginRight: 2,
            }}
          >
            <EllipsisText text={item.name} line={1} maxWidth={150} />
          </Box>
          <ButtonControlled
            title="Edit"
            handleClick={() => {
              if (titleInput.current) {
                titleInput.current?.focus();
              }
              setIsEdit(true);
            }}
            iconNode={<Icon icon="lucide:pencil" color="black" />}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <CustomizedInputsStyled
            ref={titleInput}
            defaultValue={item.name}
            handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeValueNameAsset(event)
            }
          />

          <ButtonControlled
            title=""
            handleClick={() => setIsEdit(false)}
            iconNode={<img src={GoBackIcon} alt="" width={18} height={18} />}
          />
          <ButtonControlled
            title=""
            handleClick={() => {
              if (!value) {
                toast.error('Name cannot be blank.', {
                  duration: 2000,
                });
                return;
              }
              setIsEdit(false);
              handleChange(item.id, value, 'name');
            }}
            iconNode={<img src={TickGreenIcon} alt="" width={18} height={18} />}
          />
        </Box>
      )}
      <Box>
        <ButtonControlled
          title="Delete"
          handleClick={handleRemove}
          iconNode={<img src={TrashIcon} alt="" width={18} height={18} />}
        />
      </Box>
    </Box>
  );
};
